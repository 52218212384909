<script>
    import { getFieldLabels } from 'Util/forms.js'
    export let field = {};
    export let id = '';

    let {slug, name} = getFieldLabels(id, field);
</script>

<div class="c-input c-input--phone">
    <label for="{slug}">{field.label}</label>
    <input id="{slug}" type="text" name="{name}">
</div>