<script>
    import { fly } from 'svelte/transition';

    export let lid = '126';
    export let mid = '100002038';

    let { location } = window;
    let { href } = location;
    let errorUrl = new URL(href);  
    let successUrl = new URL(href);

    let email = '';
    let showMessage = false;
    let submitted = false;
    let formEl;

    successUrl.hash = '#success';

    $: valid = !!email.match(/.+@.+\..+/);

    function triggerValidity() {
        showMessage = true;
    }

    function keyboardSubmit(event) {
        var charCode = event.which || event.keyCode;
        if (charCode == 13) {
            submitFn();
        }
    }

    function submitFn(e) {
        if (e && e.preventDefault) e.preventDefault();
        submitted = true;
        window.formEvent = e;
        const formData = new URLSearchParams(new FormData(e.target || formEl));
        fetch(e.target.action, {
            method: 'POST',
            mode: 'no-cors',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData,
        });
        return false;
    }
</script>

<div class="c-sub__scene">
  <div class="c-sub {submitted ? 'c-sub--flip' : ''}">
    <div class="c-sub__inner">
      <form bind:this={formEl} on:submit={submitFn} action="https://cl.s10.exct.net/subscribe.aspx" name="subscribeForm" method="post">
        <input type=hidden name="thx" value={successUrl} />
        <input type=hidden name="err" value={errorUrl} />
        <input type=hidden name="SubAction" value="sub" />
        <input type=hidden name="MID" value={mid} />
        <input type=hidden name="LID" value={lid} />

        <input on:keydown={keyboardSubmit} on:blur="{triggerValidity}" bind:value={email} id="subscribeEmail" name="Email Address" type="email" class="c-sub__input" placeholder="someone@example.com">
        <label for="subscribeEmail" class="c-sub__label {(!valid && showMessage) ? 'c-sub__label--hide' : ''}">Email Address</label>
        {#if !valid && showMessage}
        <span transition:fly="{{duration: 200, x: 0, y: -50, opacity: 0}}" class="c-sub__label c-sub__label--err">Please enter a valid email</span>
        {/if}
        <button type="submit" class="c-link c-link--outbound c-sub__button {valid ? '' : 'c-sub__button--disabled'}">Opt in</button>
      </form>
    </div>
    <div class="c-sub__success">
      <p class="c-sub__success-text">Sent! Thank you!</p>
    </div>
  </div>
</div>