<script>
    import Field from './components/Field.svelte'
import Dropdown from './components/fields/Dropdown.svelte';
    export let form = {};
    export let translationMap = {};

    let submitted = false;
    let smallFormat = form.form_fields.length === 1;
    let isScreenReader = true;
    let notScreenReader = () => isScreenReader = false;
    let recaptchaError = '';

    let formAdmin = form.form_admin || {};
    let hiddenFields = formAdmin.hidden_fields || [];
    
    let submit = {
        type: "submit",
        label: "Send",
        thin: (formAdmin.recaptcha && formAdmin.recaptcha.enabled),
        smallFormat: smallFormat
    }

    let captchaSettings = {
        "keyname":"Ad_Sales",
        "fallback":"true",
        "orgId": "",
        "ts": JSON.stringify(new Date().getTime())
    };

    if (formAdmin.recaptcha && formAdmin.recaptcha.enabled) {
        captchaSettings.keyname = formAdmin.recaptcha.keyname;
        captchaSettings.orgId = formAdmin.recaptcha.org_id;
    }

    function submitFn(e) {
        var ableToSubmit = true;
        var dropdowns = this.getElementsByClassName('c-input--dropdown');
        for (var i =0; i < dropdowns.length; i++)
        {
            var selectBox = dropdowns[i].getElementsByTagName('select');
            var selectVal = selectBox[0].options.selectedIndex;
            if (selectVal <= 0)
            {
                console.log(selectBox.required);
                dropdowns[i].click();
                ableToSubmit = false;
            }
        }
        if (!ableToSubmit)
        {
            e.preventDefault();
            return false;
        }
        if (formAdmin.recaptcha && formAdmin.recaptcha.enabled) {
            var response = grecaptcha.getResponse();
            if(response.length == 0) {
                recaptchaError = 'This field is required.';
                return false;
            }
        }
        return true;
    }

    window.verifyCaptcha = () => {
        recaptchaError = '';
    }

    function timestamp() { 
        var response = document.getElementById("g-recaptcha-response"); 
        if (response == null || response.value.trim() == "") {
            captchaSettings.ts = JSON.stringify(new Date().getTime());
        } 
    } 

    function getTranslatedCopy(key, copy) {
        return `${translationMap[key] ? translationMap[key].replace(/&nbsp;/g, ' ') : copy}`;
    }

    setInterval(timestamp, 500);
</script>

{#if !submitted}
<form 
    class="c-form t:w u-bleed-right" 
    class:is-clicking={!isScreenReader}
    class:c-form--small={smallFormat}
    on:mousedown|once={notScreenReader}
    on:submit={submitFn}
    action={formAdmin.submission_url}
    method="POST"
    >

{#if formAdmin.debug}
    <input type="hidden" name="debug" value=1>
    <input type="hidden" name="debugEmail" value="{formAdmin.debug_email}">
{/if}

{#each hiddenFields as field}
    <input type=hidden name="{field.name}" value="{field.name == 'retURL' ? `${window.location.origin}${field.value}`: field.value}" />
{/each}
{#each form.form_fields as field}
    <Field field={{...field, smallFormat}} id={form.id} getTranslatedCopy={getTranslatedCopy}/>
{/each}

{#if formAdmin.recaptcha && formAdmin.recaptcha.enabled}
    <input type=hidden name='captcha_settings' value='{JSON.stringify(captchaSettings)}'>
    <div class="g-recaptcha" data-sitekey="{formAdmin.recaptcha.sitekey}" data-callback="verifyCaptcha" align="center" style="display: inline-flex;"></div>
{/if}

    <Field field={submit} id={form.id} getTranslatedCopy={getTranslatedCopy}/>


{#if recaptchaError}
    <div>{recaptchaError}</div>
{/if}
</form>
{:else}
<p>Thank you</p>
{/if}