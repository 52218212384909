<script>
    import { onMount } from 'svelte';
    import { gestures } from '@composi/gestures';
    import { containRegionInMap, continentData } from '../util/map.js';
    import MapDot from './components/MapDot.svelte';
    import MapContinent from './components/MapContinent.svelte';

    export let mapURL = '/assets/contact/map.svg';
    export let points = [];
    export let heading = "";

    let mapEl;
    let renderContinents = false;
    let focus = "all";
    let inlineMapStyle = '', inlineNavStyle = '', inlineThumbStyle = '';
    let currentScale = 1;

    // Initialize the gestures:
    gestures()


    let continentObj = {};
    for (let point of points) {
        continentObj[point.map_continent] = continentObj[point.map_continent] || [];
        point.i = continentObj[point.map_continent].length;
        continentObj[point.map_continent].push(point);
    }

    const availableContinents = Object.keys(continentData);
    const continentPositions = Object.values(continentData);
    const continentSwipeOrder = Object.keys(continentObj)
                        .sort((a, b) => availableContinents.indexOf(a) - availableContinents.indexOf(b));
    const continents = continentSwipeOrder.map(v => {return {name: v, points: continentObj[v]}});

    $: if (focus && mapEl) {
        let mapWidth = mapEl.clientWidth, mapHeight = mapEl.clientHeight;
        let i = continentSwipeOrder.indexOf(focus);
        if (i === -1) i = 0;
        let c = continentData[focus];
        c = c || {left: 0, top: 0, width: 100, height: 100}

        let contained = containRegionInMap(mapWidth, mapHeight, c.width, c.height);

        currentScale = contained.scale;

        inlineMapStyle = [
          `transform: translateX(${contained.left}%) translateY(${contained.top}%) scale(${contained.scale}) translateX(-${c.left}%) translateY(-${c.top}%)`,
        ].join('; ');

        inlineNavStyle = [
            `transform: translateX(-${i}00%)`
        ].join('; ');

        inlineThumbStyle = [
            `width: ${100 / continentSwipeOrder.length}%`,
            `transform: translateX(${i}00%)`
        ].join('; ');
    }

    function changeContinentFocus(n) {
        if (focus == "all") {
            focus = continentSwipeOrder[0];
        } else {
            let i = continentSwipeOrder.indexOf(focus);
            if (i === -1) {
                focus = "all";
                return;
            };
            i += n;
            i %= continentSwipeOrder.length;
            if (i < 0) i = continentSwipeOrder.length - 1;
            focus = continentSwipeOrder[i];
        }
    }

    function nextCont(e) {
        e.preventDefault();
        changeContinentFocus(1);
    }

    function prevCont(e) {
        e.preventDefault();
        changeContinentFocus(-1);
    }

    onMount(async () => {
        if (window.innerWidth < 750) {
            focus = continentSwipeOrder[0];
        }
    });
</script>

<div class="c-map" 
     on:swiperight={prevCont} 
     on:swipeleft={nextCont} >

    <div class="c-map__overflow">
        <div class="c-map__heading c-page-heading">
            <h1 class="c-page-heading__text">
                {@html heading}
            </h1>
        </div>

        <div class="c-map__map" bind:this={mapEl}>
            <div class="c-map__inner" style={inlineMapStyle}>
                <img src="{mapURL}" alt="A map of the world" class="c-map__img">

                <ul class="c-map__list">

                {#each points as point}
                    <MapDot 
                        point={point} 
                        scale={currentScale}
                        focus={focus} />
                {/each}

                {#if renderContinents}
                {#each continentPositions as c}
                    <MapContinent continent={c} />
                {/each}
                {/if}

                </ul>
            </div>
        </div>
    </div>

    <div class="c-map__bar">
        <div class="c-map__thumb" style={inlineThumbStyle}></div>
    </div>

    <ul class="c-map__nav" style={inlineNavStyle}>
    {#each continents as continent}
        <li class="c-map__nav-col">
            <p class="c-map__continent">{continent.name}</p>
            <ul class="c-map__countries">
            {#each continent.points as point}
                <li class="c-map__country">{point.name}</li>
            {/each}
            </ul>
        </li>
    {/each}
    </ul>
</div>