import { spanWrap } from './text.js';

export const getFieldLabels = (id, field, getTranslatedCopy) => {
    let name;
    if (field.field_name) {
        name = field.field_name;
    } else {
        name = slugify(`${field.label}`);
    }
    let slug = slugify(`${id}:${name}`);
    let label = getTranslatedCopy(slug, field.label);
    let labelHTML = />/.test(label) ? label : spanWrap(label);

    return {slug, name, labelHTML};
}

export const slugify = (s) => {
  return s.toString().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/,+/g, '-')
    .replace(/[^\w\-:]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}