<script>
    import FieldWrapper from './subfields/FieldWrapper.svelte';
    import { getFieldLabels } from 'Util/forms.js';
    export let field = {};
    export let id = '';
    export let getTranslatedCopy = (key, copy) => copy;

    let {slug, name, labelHTML} = getFieldLabels(id, field, getTranslatedCopy);

    let value = '';
    let verify = (v) => !!v.length;
</script>

<FieldWrapper value={value} verify={verify} type="email" field={field} id={id} getTranslatedCopy={getTranslatedCopy}>
    <label class="c-input__label" for="{slug}">{@html labelHTML}</label>
    <input class="c-input__input" bind:value={value} id="{slug}" type="email" name="{name}" required={field.required}>
</FieldWrapper>