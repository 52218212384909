<script>
    import FieldWrapper from './subfields/FieldWrapper.svelte';
    import { getFieldLabels } from 'Util/forms.js';
    export let field = {};
    export let id = '';
    export let getTranslatedCopy = (key, copy) => copy;

    let {slug, name, labelHTML} = getFieldLabels(id, field, getTranslatedCopy);
    let salesforce = false;

    if (field.extras && field.extras.split_into_salesforce_names) {
        salesforce = true;
    }

    let value = '', firstName = '', lastName = '';
    let verify = (v) => !!v.length;

    $: {
        firstName = value.substr(0, value.indexOf(' '));
        lastName = value.substr(value.indexOf(' ') + 1);

        if (!firstName.length) {
            firstName = lastName;
            lastName = '';
        }
    }
</script>

<FieldWrapper value={value} verify={verify} type="text" field={field} id={id} getTranslatedCopy={getTranslatedCopy}>
    <label class="c-input__label" for="{slug}">{@html labelHTML}</label>
    <input class="c-input__input" bind:value={value} id="{slug}" type="text" name="{name}" required={field.required}>
</FieldWrapper>

{#if salesforce}
    <input class="c-input__input" bind:value={firstName} id="{slug}-firstname" type="hidden" name="first_name">
    <input class="c-input__input" bind:value={lastName} id="{slug}-lastname" type="hidden" name="last_name">
{/if}