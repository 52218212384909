export const containRegionInMap = (mapWidth, mapHeight, w, h) => {
  let outerRatio = mapWidth / mapHeight;
  let innerRatio = w / h;
  
  let scale, top, left;
  
  if (innerRatio <= outerRatio) {
    top = 0;
    scale = 100/h;
    let scaledW = w * scale;
    left = (50 - (scaledW / 2));
  } else {
    left = 0;
    scale = 100/w;
    let scaledH = h * scale;
    top = (50 - (scaledH / 2));
  }
  
  return {scale, top, left}
}

export const continentData = {
    "North America": {
        left:   0,
        top:    13,
        width:  34,
        height: 54
    },
    "South America": {
        left:   20,
        top:    60,
        width:  20,
        height: 43
    },
    "Europe": {
        left:   38,
        top:    16,
        width:  27,
        height: 43
    },
    "Asia-Pacific": {
        left:   60,
        top:    25,
        width:  40,
        height: 50
    },
    "Africa": {
        left:   40,
        top:    50,
        width:  23,
        height: 39
    },
    "Oceania": {
        left:   75,
        top:    65,
        width:  23,
        height: 34
    }
};