<script>
    import FieldWrapper from './subfields/FieldWrapper.svelte';
    import { getFieldLabels } from 'Util/forms.js';
    export let field = {};
    export let id = '';
    export let getTranslatedCopy = (key, copy) => copy;

    let {slug, name} = getFieldLabels(id, field, getTranslatedCopy);
</script>

<FieldWrapper type="submit" field={field} id={id} getTranslatedCopy={getTranslatedCopy}>
    <input class="c-input__submit" id="{slug}" type="submit" name="{name}" value="{getTranslatedCopy(slug, field.label)} ↗">
</FieldWrapper>