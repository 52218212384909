<script>
    import { slugify } from 'Util/forms.js';

    export let value;
    export let option;
    export let delay = 0;
    export let open;
    export let slug = '';
    export let setOpen = () => {};
    export let getTranslatedCopy = (key, copy) => copy;

    let animDelay = (delay+3) * 90;
</script>

<li 
    class="c-input__listbox-item" 
    class:is-selected={value === option}
    class:is-open={open}
    aria-selected={value === option}
    on:click={() => {value = option; setOpen(false)}}
    id={slugify(option)} 
    role="option"
    style="animation-delay: {animDelay}ms;">
  {getTranslatedCopy(`${slug}-${slugify(option)}`, option)}
</li>