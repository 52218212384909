<script>
    import * as KeyCode from 'keycode-js';
    
    export let value;
    // export let options = [];
    export let setOpen;
    export let slug;
    export let valid;
    export let required;
    export let ref;
    export let getTranslatedCopy = (key, copy) => copy;

    let catchKeyboard = (e) => {
        let key = e.which || e.keyCode;

        let i, nextVal;
        switch (key) {
            case KeyCode.KEY_UP:
                e.preventDefault();
                setOpen(true);
                // i = options.indexOf(value);
                // nextVal = options[i-1];
                // value = nextVal !== undefined ? nextVal : value;
                break;
            case KeyCode.KEY_DOWN:
                e.preventDefault();
                setOpen(true);
                // i = options.indexOf(value);
                // nextVal = options[i+1];
                // value = nextVal !== undefined ? nextVal : value;
                break;
            case KeyCode.KEY_ENTER:
            case KeyCode.KEY_RETURN:
                e.preventDefault();
                setOpen(true);
                break;
        }
    }
</script>

<button 
    class="c-input__button"
    class:has-content={valid}
    on:click={() => {setOpen(true)}} 
    on:keydown={catchKeyboard}
    bind:this={ref}
    type="button"
    aria-haspopup="listbox" 
    aria-labelledby="{slug} {slug}-button"
    aria-owns="{slug}-list"
    aria-required={required}
    id="{slug}-button" >{value || getTranslatedCopy(`${slug}-none`, 'None Selected')}</button>