<script>
    import FieldWrapper from './subfields/FieldWrapper.svelte';
    import { getFieldLabels } from 'Util/forms.js';
    export let field = {};
    export let id = '';
    export let getTranslatedCopy = (key, copy) => copy;
    let has_max_char = false;

    let {slug, name, labelHTML} = getFieldLabels(id, field, getTranslatedCopy);

    if (field.max_characters_number) {
        has_max_char = field.max_characters_number;
    }

    let value = '';
    let verify = (v) => !!v.length;
</script>

<FieldWrapper value={value} verify={verify} type="textarea" field={field} id={id} getTranslatedCopy={getTranslatedCopy}>
    <label class="c-input__label" for="{slug}">{@html labelHTML}</label>
    <textarea bind:value={value} class="c-input__area" name="{name}" id="{slug}" required="{field.required}" ></textarea>
    {#if has_max_char}
    <span class="c-input__char-count" class:is-over={value.length > has_max_char}>{has_max_char - value.length}</span>
    {/if}
</FieldWrapper>