<script>
    import { onMount } from 'svelte';
    import { spanWrap } from '../../util/text.js';
    export let point = {};
    export let scale = 1;
    export let focus = 'all';

    const projWidth = 100;      // Set projection constants to 100
    const projHeight = 100;     // To be used as percentages later

    const xOffset = 3.7;        // Percentage that our map SVG is offset
    const yOffset = 4.9;        // from a square Mercator projection

    const xScale = 99.5;          // Percentage that our map SVG is scaled
    const yScale = 63.58;       // from a square Mercator projection


    let lat = point.latitude_number || 0;
    let lon = point.longitude_number || 0;
    let x;
    let y;
    let inlineStyle = '';
    let lhs = false;
    let labelEl, dotEl;
    let safeAfterMount = false;

    let wrappedNameHTML = spanWrap(point.name.split(',')[0], 'c-map__label-text', 200, 450)

    if (lat && lon) {
        try {
            lat = parseFloat(lat);
            lon = parseFloat(lon);

            let latRad = lat*Math.PI/180;
            let mercN = Math.log(Math.tan((Math.PI/4)+(latRad/2)));

            x = (lon+180)*(projWidth/360)
            y = (projHeight/2)-(projWidth*mercN/(2*Math.PI));

            x -= xOffset;
            y -= yOffset;

            x /= xScale;
            y /= yScale;

            x *= 100;
            y *= 100;
        } catch(e) {
            console.error("Error placing dot on map");
        }
    }

    $: {
        inlineStyle = [
            `transform: scale(${1/scale})`,
            `left: ${x}%`,
            `top: ${y}%`,
            `transition-delay: ${point.i+5}00ms`
        ].join('; ');
    }

    onMount(async () => {
        handleResize();
        setTimeout(() => {safeAfterMount = true}, 500)
    });

    function handleResize() {
        if (!labelEl || !dotEl) return;
        let labelPos = labelEl.getBoundingClientRect();
        let dotPos = dotEl.getBoundingClientRect();
        let farX = dotPos.x + dotPos.width + labelPos.width;
        let bufferX = window.innerWidth - 50;
        if (farX > bufferX) {
            lhs = true;
        } else {
            lhs = false;
        }
    }
</script>

<svelte:window on:resize={handleResize}/>

{#if x && y}
<li class="c-map__dot"
    class:is-active={safeAfterMount && (focus === 'all' || focus === point.map_continent)}
    style={inlineStyle} 
    bind:this={dotEl}>
    <span class="c-map__label" 
          class:is-lhs={lhs}
          bind:this={labelEl}>
        {@html wrappedNameHTML}
    </span>
</li>
{/if}