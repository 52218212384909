<script>
    import { tick } from 'svelte';

    import FieldWrapper from './subfields/FieldWrapper.svelte';
    import Listbox from './subfields/Listbox.svelte';
    import ListboxButton from './subfields/ListboxButton.svelte';

    import { getFieldLabels, slugify } from 'Util/forms.js';
    export let field = {};
    export let id = '';
    export let getTranslatedCopy = (key, copy) => copy;

    let emptyValue = '';
    let {slug, name, labelHTML} = getFieldLabels(id, field, getTranslatedCopy);
    field.dropdown_options.unshift(emptyValue);

    let value = emptyValue, 
        listboxOpen = false,
        listboxDOM,
        buttonDOM;
    // Check for empty values
     let verify = (v) => v !== emptyValue;
    let existence = (v) => v !== emptyValue;

    let setOpen = async (o,e) => {
        listboxOpen = o;
        await tick();
        if (o) listboxDOM.focus();
        else if (e && e instanceof KeyboardEvent) buttonDOM.focus();
    }
</script>

<FieldWrapper value={value} verify={verify} existence={existence} type="dropdown" field={field} id={id} getTranslatedCopy={getTranslatedCopy}>
    <select class="c-input__puppet" bind:value={value} name="{name}" id="{slug}" aria-hidden="true" tabindex="-1" required="{field.required}" >
    {#each field.dropdown_options as option}
        <option value="{option}">{option}</option>
    {/each}
    </select>
    <span 
        class="c-input__label" 
        class:is-open={listboxOpen}
        id="{slug}">{@html labelHTML}</span>

    <ListboxButton
        bind:ref={buttonDOM}
        bind:value={value}
        setOpen={setOpen}
        options={field.dropdown_options}
        slug={slug}
        valid={verify(value)}
        required={field.required}
        getTranslatedCopy={getTranslatedCopy} />

    <Listbox 
        bind:ref={listboxDOM}
        bind:value={value}
        open={listboxOpen}
        options={field.dropdown_options}
        slug={slug}
        setOpen={setOpen}
        getTranslatedCopy={getTranslatedCopy} />
</FieldWrapper>
