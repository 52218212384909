<script>
    import FieldWrapper from './subfields/FieldWrapper.svelte';
    import { getFieldLabels } from 'Util/forms.js';
    export let field = {};
    export let id = '';
    export let getTranslatedCopy = (key, copy) => copy;

    let {slug, name, labelHTML} = getFieldLabels(id, field, getTranslatedCopy);

    let value = '0';
    let checked = false;
        function toggleValue(){
        checked = !checked;
        value = (checked ? '1' : '0');
    }
    let verify = (v) => true;

</script>

<FieldWrapper value={value} verify={verify} type="checkbox" field={field} id={id} getTranslatedCopy={getTranslatedCopy}>
    <input class="c-input__checkbox" id="{slug}" type="checkbox" name="{name}" required={field.required} value="{value}" on:click={toggleValue}>
    <label class="c-input__label" for="{slug}">{@html labelHTML}</label>
</FieldWrapper>