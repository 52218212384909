import * as Apps from './apps';

const components = Object.values(Apps);

/**
 * Initialize all components into their respective rendering targets
 * @param  {Object} defaultParams   A set of default props to pass to all Svelte components
 * @return {Function}               Initialization function for any later dynamic components
 */
export default (defaultParams) => {
  let init = (config) => {
    let component = getNormalizedComponent(config);
    let { props } = component;

    initializeComponent({ ...component, props: { ...defaultParams, ...props } });
  };

  // initialize default components
  components.forEach(init);

  return {
    initializeComponent: init
  };
}

/**
 * Render a Svelte component into many DOM elements
 * @param  {Object} config    The Svelte component, and any existing default props
 * @return {Object}           An object describing the instances where the Svelte is running
 */
const initializeComponent = (config) => {
  let { component: Component, target, props } = getNormalizedComponent(config);
  let { name } = Component;

  let nameParam = slugifyComponent(name);
  let areas = getTargets(`[data-component="${nameParam}"]`);

  let instances = areas.map((target) => {
    let props = getProps(target, props);
    return new Component({ target, props, hydrate: true });
  });

  return { name, class: Component, instances };
};

/**
 * Find all data-* attributes from the element,
 * to be passed on as props to the Svelte component.
 * @param  {DOM Element} element    The target element to render into
 * @param  {Object} props           Existing props to map on top of
 * @return {Object}                 Merged props
 */
const getProps = (element, props) => {
  let attributes = [...element.attributes];

  attributes.forEach(({ name, value }) => {
    let [_, attr] = /^data-(.*)$/.exec(name) || [];
    if (!attr) return;

    let key = camelifyComponent(attr);

    if (key === 'translationMap') {
      // Pass a map of all i18n keys found within this component, for the Svelte to use
      // TODO: Use new Svelte JSON setup.
      const translationMap = {};
      const translatedElements = element.querySelectorAll('[data-i18n]');
      for (const el of translatedElements) {
        const translationKey = el.dataset.i18n;
        if (!translationKey) continue;
        translationKey = translationKey.toLowerCase();
        translationMap[translationKey] = el.innerHTML;
      }

      props = { ...props, [key]: translationMap };
    } else {
      // convert this property to a boolean
      if (/^(true|false)$/i.test(value)) {
        value = /^(true)$/i.test(value);
      }
  
      // attempt to parse json
      try {
        value = JSON.parse(value);
      }
      catch(_error) {
        // nope, not json
      }

      props = { ...props, [key]: value };
    }

    // cleanup the `data-` attributes
    element.removeAttribute(name);
  });

  return props;
};



// Helpers //

// Wrap the raw components into the config object if they weren't specified as such
const getNormalizedComponent = (config) => {
  if ('function' === typeof config) {
    return { component: config, props: {} };
  }

  return config;
};

// Get all rendering targets
const getTargets = (selector) => [...document.querySelectorAll(selector)];

// Go from SamHarnack to sam-harnack
const slugifyComponent = (c) => {
  return c.replace(/\B[A-Z]/g, match => `-${match}`).toLowerCase();
}

// Go from sam-harnack to SamHarnack
const camelifyComponent = (c) => {
  return c.replace(/-(.)/g, (match, s) => s.toUpperCase())
}