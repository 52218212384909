<script>
    import * as Fields from './fields';
    export let field = {};
    export let id = '';
    export let getTranslatedCopy = (key, copy) => copy;
    let FieldType;

    switch (field.type) {
        case "email":
            FieldType = Fields.Email;
            break;
        case "dropdown":
            FieldType = Fields.Dropdown;
            break;
        case "phone":
            FieldType = Fields.Phone;
            break;
        case "textarea":
            FieldType = Fields.TextArea;
            break;
        case "checkbox":
            FieldType = Fields.Checkbox;
            break;
        case "submit":
            FieldType = Fields.Submit;
            break;
        default:
            FieldType = Fields.Text;
    }
</script>

<svelte:component this={FieldType} field={field} id={id} getTranslatedCopy={getTranslatedCopy}/>