<script>
    import { slugify } from 'Util/forms.js';
    import * as KeyCode from 'keycode-js';

    import ListboxOption from './ListboxOption.svelte';

    export let open;
    export let ref;
    export let value;
    export let options = [];
    export let slug = '';
    export let setOpen = () => {};
    export let getTranslatedCopy = (key, copy) => copy;

    let catchKeyboard = (e) => {
        let key = e.which || e.keyCode;

        let i, nextVal;
        switch (key) {
            case KeyCode.KEY_PAGE_UP:
            case KeyCode.KEY_PAGE_DOWN:
                e.preventDefault();
                break;
            case KeyCode.KEY_UP:
                e.preventDefault();
                i = options.indexOf(value);
                nextVal = options[i-1];
                value = nextVal !== undefined ? nextVal : value;
                break;
            case KeyCode.KEY_DOWN:
                e.preventDefault();
                i = options.indexOf(value);
                nextVal = options[i+1];
                value = nextVal !== undefined ? nextVal : value;
                break;
            case KeyCode.KEY_HOME:
                e.preventDefault();
                break;
            case KeyCode.KEY_END:
                e.preventDefault();
                break;
            case KeyCode.KEY_ENTER:
            case KeyCode.KEY_RETURN:
            case KeyCode.KEY_ESCAPE:
                e.preventDefault();
                setOpen(false, e);
                break;
        }
    }
</script>

<ol class="c-input__listbox"
    class:is-hidden={!open}
    on:keydown={catchKeyboard}
    on:blur={() => {setOpen(false)}}
    bind:this={ref}
    id="{slug}-list"
    tabindex="-1"
    role="listbox"
    aria-labelledby="{slug}"
    aria-activedescendant={open ? slugify(value) : null } >
    {#each options as option, i}
        <ListboxOption 
            bind:value={value}
            option={option}
            delay={i}
            open={open}
            setOpen={setOpen}
            slug={slug}
            getTranslatedCopy={getTranslatedCopy}
        />
    {/each}
</ol>