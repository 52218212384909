import MicroApps from './lib/microApps';
import { plyrScripts } from './plyr/main.js';

const sleep = ms => new Promise( r => setTimeout(r, ms));


const initLax = async () => {
    while (typeof createLax === 'undefined') {
        await sleep(50);
    }

    let lax = createLax();
    window.lax = lax;

    lax.setup()
    lax.enable()

    const updateLax = () => {
        lax.update(window.scrollY)
        window.requestAnimationFrame(updateLax)
    }

    window.requestAnimationFrame(updateLax)
}

(async () => {
    MicroApps();
    plyrScripts();
    initLax();
})();