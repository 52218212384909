/**
 * The custom Plyr here is currently built from the source: https://github.com/bglw/plyr
 * Within that project, after running npm run build, 
 * dist/plyr.mjs is copied into custom-subtitles-plyr.js here.
 * 
 * This custom Plyr build enables us to pass in prefetched subtitiles from Vimeo
 * to avoid the Vimeo/Plyr visual caption glitch referenced here:
 * https://github.com/sampotts/plyr/issues/877
 * 
 * Places to look:
 *  - https://github.com/bglw/plyr for Plyr changes
 *  - getCaptionsFromId and getTracksFromObj down below
 *  - _plugins/fetch_vimeo_info in this site
 *  - plyr-embed.html in twitch-company-theme
 */

import Plyr from './custom-subtitles-plyr';
import controlsTemplate from './controls.html';
import { getLocale } from './locales';

function getControlMarkup () {
    //console.log(controlsTemplate);
    return controlsTemplate;
}

export const plyrScripts = () => {
    //console.log("Initialising plyr");
    if (document.readyState === "complete" 
     || document.readyState === "loaded" 
     || document.readyState === "interactive") {
        initPlyr();
    } else {
        document.addEventListener('DOMContentLoaded', () => {initPlyr()});
    }
    function initPlyr() {
        window.Plyr = Plyr;

        const pageUrl = new URL(window.location);
        // Great for testing: add ?vimeo_locale=fr to see another language
        const override_locale = pageUrl.searchParams.get('vimeo_locale');

        const videos = document.querySelectorAll('[data-plyr]');
        videos.forEach(video => {

            // Setup the player
            let { vimeo: vimeo_language } = getLocale();
            if (override_locale) vimeo_language = override_locale;
            vimeo_language = vimeo_language.toLowerCase(); // plyr normalizes all languages

            // Fetch a TextTrackList of our custom captions. 
            // See getCaptionsFromId below.
            const vimeo_id = video.dataset.plyr;
            const vimeo_custom_captions = getCaptionsFromId(vimeo_id, vimeo_language);

            const player = new Plyr(video, {
                title: video.dataset['plyr-title'] || 'Video',
                debug: false,
                controls: getControlMarkup,
                captions: {
                    active: true,
                    language: vimeo_language,
                    // pass off the TextTrackList we fetched to our custom Plyr
                    custom: vimeo_custom_captions
                },
                keyboard: {
                    focused: true,
                    global: false
                },
                invertTime: false
            });
            window.wow = player;

            player.on('ready', event => {
                //console.log(video.selector + " ready");

                function togglePlay () {
                    if (player.playing) {
                        player.pause();
                    } else {
                        player.play();
                    }
                }

                let parent = player.media.parentElement.parentElement;

                // remove overlay and show controls when video plays
                let overlay = parent.querySelector(`.c-plyr-overlay`);
                let controls = parent.querySelector(`.c-controls`);
                player.on('play', function (event) {
                    overlay.classList.add("c-plyr-overlay--hidden");
                    controls.classList.remove("c-controls--hidden");
                });

                let range = parent.querySelector(`.c-progress-bar__input`);
                // focus range on clicking container, so keyboard commands can be used
                parent.addEventListener('click', function() {
                    range.focus();
                });

                // shove something into this variable to avoid null reference in plyr library
                if (!player.elements.progress) {
                    player.elements.progress = range;
                }

                // manage progress bar gradient
                let rangeSlider = parent.querySelector(`.c-progress-bar__after`);
                player.on('timeupdate', function (event) {
                    if (player.duration) {
                        let progress = (player.currentTime / player.duration) * 100;
                        rangeSlider.style.setProperty('--slider-progress', `${100 - progress}%`)
                    }
                });

                // manage play/pause icon
                let playButton = parent.querySelector(`.c-controls__toggle-play`);
                player.on('playing', function (event) {
                    playButton.classList.remove("c-controls__toggle-play--paused")
                });
                player.on('pause', function (event) {
                    playButton.classList.add("c-controls__toggle-play--paused")
                });

                // manage fullscreen icon
                let fullscreenButton = parent.querySelector(`.c-controls__toggle-fullscreen`);
                player.on('exitfullscreen', function (event) {
                    fullscreenButton.classList.remove("c-controls__toggle-fullscreen--full")
                });
                player.on('enterfullscreen', function (event) {
                    fullscreenButton.classList.add("c-controls__toggle-fullscreen--full")
                });

                // manage mute icon
                let muteButton = parent.querySelector(`.c-controls__toggle-mute`);
                player.on('volumechange', function (event) {
                    if (player.muted) {
                        muteButton.classList.add("c-controls__toggle-mute--muted");
                    } else {
                        muteButton.classList.remove("c-controls__toggle-mute--muted");
                    }
                })

                // mobile controls
                parent.addEventListener('touchstart', function(event) {
                    togglePlay();
                });
                controls.addEventListener('touchstart', function(event) {
                    event.stopPropagation();
                });

            })

        });

    };
}

/**
 * Some work has been done ahead of here in a plugin.
 * A script tag on this page should have put references to
 * text tracks on window.plyr_vimeo_data[vimeo_id].

 * Here we grab those references from the window, 
 * and look for one in our chosen language.

 * See getTextTracksFromObj below.
 */
const getCaptionsFromId = (vimeo_id, vimeo_language) => {
    if (!window.plyr_vimeo_data) return [];

    const vimeo_data = window.plyr_vimeo_data[vimeo_id];
    if (!vimeo_data || !Array.isArray(vimeo_data)) return [];

    for (let track of vimeo_data) {
        track.lang = track.lang.toLowerCase(); // plyr normalizes all languages
        if (track.lang === vimeo_language) {
            return getTextTracksFromObj(track);
        }
    }

    return [];
}

/**
 * The "Object" here has come from a script tag output on the page by fetch_vimeo_info.rb
 * This object itself is pulled from the Vimeo config url (e.g. https://player.vimeo.com/video/487426548/config)
 * In addition to that, the texttracks referenced in that config have been output on this site
 * which is why we can use the URLs directly.
 * 
 * Here we make a fake video element and add this object as a track.
 * This triggers the browser to download and parse the VTT, so that we don't have to :raised-hands:
 * Once that's done, we return the TextTrackList object, which is what we pass to Plyr
 */
const getTextTracksFromObj = (o) => {
    let video = document.createElement('video');
    let track = document.createElement('track');
    track.kind = 'captions';
    track.label = o.label;
    track.srclang = o.lang;
    track.default = true;
    track.src = o.url;
    video.appendChild(track);
    return video.textTracks;
}