const LOCALES = [
  { lang: 'ar-sa', twilight: 'ar', help: 'en', vimeo: null },
  { lang: 'bg-bg', twilight: 'bg', help: 'en', vimeo: 'bg' },
  { lang: 'cs-cz', twilight: 'cs', help: 'en', vimeo: 'cs' },
  { lang: 'da-dk', twilight: 'da', help: 'da', vimeo: 'da' },
  { lang: 'de-de', twilight: 'de', help: 'de', vimeo: 'de' },
  { lang: 'el-gr', twilight: 'el', help: 'en', vimeo: 'el' },
  { lang: 'en-gb', twilight: 'en-gb', help: 'en', vimeo: 'en' },
  { lang: 'en', twilight: 'en', help: 'en', vimeo: 'en-US' },
  { lang: 'es-es', twilight: 'es', help: 'es_es', vimeo: 'es-ES' },
  { lang: 'es-mx', twilight: 'es-mx', help: 'es', vimeo: 'es-MX' },
  { lang: 'fi-fi', twilight: 'fi', help: 'fi', vimeo: 'fi' },
  { lang: 'fr-fr', twilight: 'fr', help: 'fr', vimeo: 'fr' },
  { lang: 'hi-in', twilight: 'hi', help: 'en', vimeo: null },
  { lang: 'hu-hu', twilight: 'hu', help: 'en', vimeo: 'hu' },
  { lang: 'it-it', twilight: 'it', help: 'it', vimeo: 'it' },
  { lang: 'ja-jp', twilight: 'ja', help: 'ja', vimeo: 'ja' },
  { lang: 'ko-kr', twilight: 'ko', help: 'ko', vimeo: 'ko' },
  { lang: 'nl-nl', twilight: 'nl', help: 'nl', vimeo: 'nl' },
  { lang: 'no-no', twilight: 'no', help: 'no', vimeo: 'no' },
  { lang: 'pl-pl', twilight: 'pl', help: 'pl', vimeo: 'pl' },
  { lang: 'pt-br', twilight: 'pt-br', help: 'pt_br', vimeo: 'pt-BR' },
  { lang: 'pt-pt', twilight: 'pt', help: 'pt_br', vimeo: 'pt-PT' },
  { lang: 'ro-ro', twilight: 'ro', help: 'en', vimeo: 'ro' },
  { lang: 'ru-ru', twilight: 'ru', help: 'ru', vimeo: 'ru' },
  { lang: 'sk-sk', twilight: 'sk', help: 'en', vimeo: 'sk' },
  { lang: 'sv-se', twilight: 'sv', help: 'sv', vimeo: 'sv' },
  { lang: 'th-th', twilight: 'th', help: 'th', vimeo: 'th' },
  { lang: 'tr-tr', twilight: 'tr', help: 'tr', vimeo: 'tr' },
  { lang: 'vi-vn', twilight: 'vi', help: 'vi', vimeo: 'vi' },
  { lang: 'zh-cn', twilight: 'zh-cn', help: 'zh_cn', vimeo: 'zh-Hans' },
  { lang: 'zh-tw', twilight: 'zh-tw', help: 'zh_tw', vimeo: 'zh-TW' }
];

export const getLocale = () => {
  let lang = document.documentElement.lang;

  let locales = LOCALES.reduce((result, locale) => {
    if (result) return result;
    if (lang && lang.toLowerCase() === locale.lang) return locale;
  }, null);

  if (locales) {
    return Object.assign({ lang }, locales);
  }
};
