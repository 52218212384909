<script>
    import Checkbox from '../Checkbox.svelte';
    export let verify = () => true;
    export let existence = (v) => v.match(/\S/);
    export let value = '';
    export let type = 'text';
    export let activated = false;
    export let field = {};
    export let id = '';
    export let getTranslatedCopy = (key, copy) => copy;

    let extras = field.extras || {};
    let extraField = {};
    if (extras.has_checkbox) {
        extraField = {
            type: 'checkbox',
            label: extras.checkbox_label,
            field_name: extras.checkbox_field_name,
            required: extras.required,
            sub: true
        }
    }

    let activate = () => activated = true;
</script>

<div    on:click|once={activate} 
        class="c-input c-input--{type}" 
        class:c-input--small={field.smallFormat}
        class:c-input--thin={field.thin}
        class:has-content={existence(value)}
        class:has-error={activated && !verify(value)}
        class:is-activated={activated}
        class:is-sub={field.sub}>
    <div class="c-input__focusbox"><slot></slot></div>
    {#if extras.has_checkbox}
    <Checkbox id={id} field={extraField} getTranslatedCopy={getTranslatedCopy}/>
    {/if}
</div>